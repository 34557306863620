video::-webkit-media-text-track-display {
    width: auto !important;
    max-width: 100%;
    left: 0 !important;
    right: 0 !important;
    margin-left: auto;
    margin-right: auto;
    text-align: center !important;
    font-size: 1.2em;
}

@media (orientation: portrait) {
  video {
    width: 100%;
    height: auto !important;
    aspect-ratio: 16 / 9;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
  }
}

@keyframes delay-pointer-events {
  0% {
    visibility: hidden;
  }
  90% {
      visibility: hidden;
  }
  100% {
      visibility: visible;
  }
}

/* Delay pointer events via visibility tiny delay */
.HideableContainer > div, .HideableContainer .Timeline, .HideableContainer button {
  visibility: hidden;
  pointer-events: none;
  touch-action: none;
}

.HideableContainer.showing-controls > div, .HideableContainer.showing-controls .Timeline, .HideableContainer.showing-controls button {
  animation: delay-pointer-events 200ms linear;
  animation-fill-mode: forwards;
  pointer-events: auto;
}

/* GPU Accelerated timeline */
div:has(> div > div.Timeline) * {
  transform: translate3d(0,0,0);
}